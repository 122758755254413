import React from "react";
import FacebookIcon from '@material-ui/icons/Facebook';

import Layout from "../components/layout";
import contact from '../images/contacts.png';

function AboutPage() {
  return (
    <Layout name="contact">
      <div className="text-gray-500 flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 h-1/2 md:min-h-full p-2">
          <div className="flex content-center items-center overflow-hidden">
            <img className="flex-shrink-0 min-w-full min-h-full" src={contact} />
          </div>
        </div>
        <div className="w-full md:w-3/5 p-2">
          <div style={{ width: '100%' }} className="h-3/5">
            <iframe width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=pt&amp;q=Caminho%20Ruival%20de%20Baixo,%20218+(Jardim%20de%20Inf%C3%A2ncia%20S%C3%A3o%20Vicente)&amp;t=m&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed&amp;mrt=yp&amp;Iwloc=near">
            </iframe>
          </div>
          <div className="h-2/5 flex flex-wrap content-center">
            <div className="leading-loose w-full p-2 m-4 rounded-lg jis-background-color text-white text-center">
              Rua da Vinha, nº 218
              <br/>
              4560-573 Penafiel
              <br/>
              255 724 195
              <br/>
              936 592 279
              <br/>
              <a href="mailto:geral@jisvicente.com">geral@jisvicente.com</a>
              <br/>
              <a className="socialLink text-lg" href="https://www.facebook.com/jisvicente"><FacebookIcon fontSize="large" /></a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
